import App from './App';
import axios from "axios";
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import * as FullStory from '@fullstory/browser';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID});

// axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
// axios.defaults.headers.post['Accept'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path='/*' element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
